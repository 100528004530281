import React from "react";
import { css, Global } from "@emotion/react";

const GlobalStyles = () => (
  <Global
    styles={css`
      html {
        box-sizing: border-box;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

    `}
  />
);

export { GlobalStyles };
