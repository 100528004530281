import { css } from '@emotion/css';

const breakpoints = {
    smallerPhone: 'max-width: 320px',
    phone: 'max-width: 480px',
    tablet:'max-width: 768px'
}

interface breakpoints {
    tablet?:any;
  }

const mediaTo:breakpoints = Object.keys(breakpoints).reduce(
    (accumulator: breakpoints, label: string) => {
        accumulator[label] = (args: any) => css`
        @media (${breakpoints[label]}) {
            ${args};
        }`;
        return accumulator;
    },
    {},
);

const buttonPrimary = (color, darker) => `
    background-color: ${color};
    border-color: ${color};

    &:hover, &:focus {
        background-color: ${darker};
        border-color: ${darker};
        transition: all .2s linear;
    }

    &:after {
        border-color: ${darker};
        box-shadow: 0 0 0 6px ${`rgba(${darker}, 0.1)`};
    }
`

const buttonSecondary = (color, darker) => `
    color: ${color};
    border-color: ${color};

    &:hover, &:focus {
        color: ${darker};
        border-color: ${darker};
        transition: all .2s linear;
    }

    &:after {
        color: ${darker};
        border-color: ${darker};
        box-shadow: 0 0 0 6px ${`rgba(${darker}, 0.1)`};
    }
`


const buttonText = (color, darker) => `
    color: ${color};

    &:hover, &:focus {
        color: ${darker};
    }

    &:after {
        border-color: ${darker};
        box-shadow: 0 0 0 4px ${`rgba(${darker}, .1)`};
    }
`


export { mediaTo, buttonPrimary, buttonSecondary, buttonText }