import * as React from 'react'
import styles from './Textarea.module.scss'
import classNames from 'classnames'
import { EInputTextType } from '../index'
import { exists } from '../../../utils/variableEvaluation'

interface IProps {
   isFocused: boolean;
   isLoading: boolean;
   name?: string;
   value?: string[] | string | number;
   placeholder: string;
   type: string;
   onChange?: (name: string, value: string) => void;
   additionalClass?: string;
   isDisabled?: boolean;
   onBlur?: any;
   autoFocus?: boolean;
   readOnly?: boolean;
   maxLength?: number;
   isDropdown?: boolean;
   rows?: number;
   cols?: number;
}

interface IState {
   //
}

export class InputTextarea extends React.Component<IProps, IState> {
   constructor(props: any) {
      super(props)
   }

   private inputRef: HTMLTextAreaElement
   private focusTimeoutId

   public componentDidMount() {
      this.focusTimeoutId = setTimeout(() => {
         if (this.props.isFocused) {
            this.inputRef.focus()
         }
      }, 0)
   }

   public componentWillUnmount() {
      clearTimeout(this.focusTimeoutId)
   }

   private handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const name = e.currentTarget.name
      const value = e.currentTarget.value
      this.props.onChange && this.props.onChange(name, value)
   }

   private handleFocus = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      console.log('handleFocus')
   }

   private handleBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const name = e.currentTarget.name
      const value = e.currentTarget.value
      this.props.onBlur && this.props.onBlur(e)
   }

   public render() {
      const {
         name,
         value,
         placeholder,
         isFocused,
         isLoading,
         additionalClass,
         isDisabled,
         readOnly,
         maxLength,
         rows,
         cols
      } = this.props

      const inputClass = classNames(styles.textareaInput, {
         [additionalClass]: exists(additionalClass),
         [styles.hasValue]: exists(value)
      })

      return (
         <div className={styles.textareaInputContainer}>
                <textarea
                   rows={rows || 5}
                   cols={cols || 5}
                   name={name}
                   readOnly={readOnly}
                   className={inputClass}
                   onBlur={this.handleBlur}
                   placeholder={placeholder}
                   onFocus={this.handleFocus}
                   onChange={this.handleChange}
                   maxLength={maxLength || 255}
                   disabled={isDisabled || isLoading}
                   value={value}
                   ref={input => isFocused && (this.inputRef = input)}
                   autoComplete="off"
                />

            {/*<div/>*/}

         </div>
      )
   }
}
