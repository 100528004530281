import styled from "@emotion/styled"
import { css } from '@emotion/css'
import {Link} from 'react-router-dom'
import {ButtonColors, ButtonStyles, ButtonTypes, Actions} from './Button'
import {buttonPrimary, buttonSecondary, buttonText} from '../../styles/utils'


export const standardButton = (theme)=> `
   display: flex;
   align-items: center;
   justify-content: center;

   color: white;
   font-weight: bold;
   font-size: 14px;
   line-height: 20px;
   text-align: center;
   text-transform: uppercase;

   position: relative;
   cursor: pointer;
   outline: none !important;
   height: 56px;
   min-width: 150px;
   padding-left: 32px;
   padding-right: 32px;
   border-width: 2px;
   border-style: solid;
   border-radius: 28px;
   border-color: black;
   background-color: black;
   transition: all .2s linear;

   &:after {
      top: -7px;
      left: -7px;
      opacity: 0;
      border-width: 2px;
      position: absolute;
      border-style: solid;
      border-radius: 36px;
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      transition: all .2s linear;
   }

   &:focus {
      &:after {
         opacity: 1;
      }
   }

   &:not(.disabled) {
         &.primaryButton {

            &.action {
               ${buttonPrimary(theme.colors.action, theme.colors.actionDarker)}
            }

            &.error {
               ${buttonPrimary(theme.colors.error, theme.colors.errorDarker)}
            }

            &.white {
               ${buttonPrimary(theme.colors.white, theme.colors.white)}
               color: ${theme.colors.actionDarker}
            }
         }

         &.secondaryButton {
            background-color: transparent;  
         
            &.action {
               ${buttonSecondary(theme.colors.action, theme.colors.actionDarker)}
            }

            &.error {
               ${buttonSecondary(theme.colors.error, theme.colors.errorDarker)}
            }

            &.white {
               ${buttonSecondary(theme.colors.white, theme.colors.whiteDarker)}
            }

            &.whiteInvert {
               color: ${theme.colors.action} !important;
               background-color: white !important;
               border-color: ${theme.colors.action} !important;

               &:hover, &:focus {
                  background-color: ${theme.colors.actionDarker};
                  border-color: ${theme.colors.actionDarker};
                  transition: all .2s linear;
               }

               &:after {
                  border-color: ${theme.colors.actionDarker};
                  box-shadow: unset;
                  width: calc(100% - 4px);
                  height: calc(100% - 4px);
                  top: 2px;
                  left: 2px;
               }
            }
         }
      }

      & .icon {
         margin-left: 25px;
         display: flex;
         justify-content: center;
         align-items: center;
      }

      &.full {
         width: 100%;
      }

      &.auto {
         width: auto;
      }

      &.medium {
         width: auto;
         font-size: 14px;
      }

      &.small {
         width: auto;
         font-size: 12px;
      }

      &.extraSmall {
         width: auto;
         font-size: 10px;
      }

      &.large {
         width: auto;
         font-size: 16px;
      }

      &.extraLarge {
         width: auto;
         font-size: 18px;
      }

      &.icon {
         justify-content: space-between;
      }

      &.disabled {
         cursor: not-allowed;
         color: ${theme.colors.greyMedium};
         border-color: ${theme.colors.greyLight};
         background-color: ${theme.colors.greyLight};
         position: relative;

         & .disableLabel {
            position: absolute;
            left: 0;
            top: calc(100% + 15px);
            text-align: left;
            font-weight: 400;
            text-transform: none;
         }
      }
   }
`

export const textButton = (theme) => `
      display: flex;
      align-items: center;
      justify-content: center;

      color: black;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;

      position: relative;
      padding: 0;
      cursor: pointer;
      outline: none !important;
      width: auto;
      border: none;
      background: none;
      transition: all .2s linear;

      &:after {
         left: -8px;
         top: -4px;
         opacity: 0;
         position: absolute;
         border-radius: 4px;
         border-width: 2px;
         border-style: solid;
         width: calc(100% + 16px);
         height: calc(100% + 8px);
         transition: opacity .2s linear;
      }

      &:focus {
         &:after {
            opacity: 1;
         }
      }

      &.action {
         ${buttonText(theme.colors.action, theme.colors.actionDarker)};
      }

      &.error {
         ${buttonText(theme.colors.error, theme.colors.errorDarker)};
      }

      &.medium {
         width: auto;
         font-size: 14px;
      }

      &.small {
         width: auto;
         font-size: 12px;
      }

      &.extraSmall {
         width: auto;
         font-size: 10px;
      }

      &.large {
         width: auto;
         font-size: 16px;
      }

      &.extraLarge {
         width: auto;
         font-size: 18px;
      }

      &.disabled {
         cursor: not-allowed;
   
         &, &:hover, &:focus {
            color: ${theme.colors.greyMedium} !important;
         }
      }
`

export const stickyButton = (theme) => `
   height: 56px;
   width: 56px;
   border-radius: 28px;
   box-shadow: 0 6px 20px 0 rgba(36, 47, 56, 0.5);
   outline: none;
   border: none;
   background-color: ${theme.colors.action};
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   cursor: pointer;

   & * {
      transition: all .2s linear;
   }

   &:hover, &:focus {
      background-color: ${theme.colors.actionDarker};
      box-shadow: 0 4px 10px -8px rgba(38, 50, 56, 0.6);
   }
`

export const button = `
   &.width-1 {
      width: 156px;
      min-width: 156px;
      max-width: 156px;
   }

   &.width-2 {
      width: 183px;
      min-width: 183px;
      max-width: 183px;
   }

   &.width-3 {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
   }

   &.width-4 {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
   }

   &.width-5 {
      width: 136px;
      min-width: 136px;
      max-width: 136px;
   }
`

export const ButtonContainer = styled.button`
   &.standardButton {
      ${({theme})=>standardButton(theme)};
   }

   &.textButton {
      ${({theme})=>textButton(theme)};
   }

   /* &.button {
      ${button}
   } */

   &.stickyButton {
      ${({theme})=>stickyButton(theme)};
   }
`

export const ExternalLink = styled.a`
   &.standardButton {
      ${({theme})=>standardButton(theme)};
   }

   &.button {
      ${button}
   }

   &.textButton {
      ${({theme})=>textButton(theme)};
   }

   &.stickyButton {
      ${({theme})=>stickyButton(theme)};
   }
`

export const InternalLink = styled(Link)`
   &.standardButton {
      ${({theme})=>standardButton(theme)};
   }

   &.button {
      ${button}
   }

   &.textButton {
      ${({theme})=>textButton(theme)};
   }

   &.stickyButton {
      ${({theme})=>stickyButton(theme)};
   }
`