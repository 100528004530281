import * as React from 'react'
import styles from './Label.module.scss'
import { renderDefinedTrue } from '../../../utils/react'


export interface Props {
   style?: any;
   for?: any;
}

export class InputLabel extends React.Component<Props, {}> {
   public render(): JSX.Element {
      return renderDefinedTrue(this.props.children, () => (
         <label className={styles.inputLabel}
                style={this.props.style}>
            {this.props.children}
         </label>
      ))
   }
}
