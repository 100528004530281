import * as React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import { CircleWidgetContainer } from './styles'
import { IProps} from './types'

export const StatisticsCircle = ({number, header, class:classColor, to, isDisabled, onClick}: IProps) => {
   return (
      <Router>
         <CircleWidgetContainer isDisabled={isDisabled}>
            <div className="widgetHeader">{header}</div>
            <div className={`circleWidget ${classColor}`}
               onClick={onClick}>
               <Link to={to} onClick={e => e.preventDefault()}>
                  <div className="innerCircle">
                     <div className="digit">{number}</div>
                  </div>
               </Link>
            </div>
         </CircleWidgetContainer>
      </Router>
   );
};
