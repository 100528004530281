import React, {Component, CSSProperties, ReactElement} from "react";
import classNames from "classnames";

import {ReactComponent as DotsIcon} from "../../../assets/dots.svg";
import stylesModule from "./TriggerPopup.module.scss";

enum Trigger {
   hover = 'hover',
   click = 'click'
}

enum Position {
   left = "left"
}

interface Props {
   content: HTMLElement | ReactElement | string;
   icon?: HTMLElement | ReactElement;
   trigger?: keyof typeof Trigger;
   position?: keyof typeof Position;
   stylesPopup?: CSSProperties;
   classNamesPopup?: string;
   stylesContentWrapper?: CSSProperties;
   classNamesContentWrapper?: string;
}

interface State {
   showPopup: boolean;
}

export class TriggerPopup extends Component<Props, State> {
   state = {
      showPopup: false,
   }

   handleShowPopup = showPopup => this.setState({showPopup});

   render() {
      const {
         content,
         icon,
         trigger = Trigger.hover,
         stylesPopup,
         stylesContentWrapper,
         classNamesContentWrapper,
         classNamesPopup,
         position
      } = this.props;
      const {showPopup} = this.state;

      return (
         <div
            className={classNames(stylesModule.triggerPopup, classNamesPopup, {[stylesModule.left]: position == Position.left})}
            style={stylesPopup}
            onClick={() => trigger === Trigger.click ? this.handleShowPopup(!showPopup) : null}
            onMouseEnter={() => trigger === Trigger.hover ? this.handleShowPopup(true) : null}
            onMouseLeave={() => trigger === Trigger.hover ? this.handleShowPopup(false) : null}
         >
            {showPopup && (
               <div
                  style={stylesContentWrapper}
                  className={classNames(stylesModule.contentWrapper, classNamesContentWrapper)}
               >
                  {content}
               </div>
            )}

            {!!icon
               ? icon
               : (
                  <DotsIcon
                     className={
                        classNames(stylesModule.dotsIcon, {
                           [stylesModule.activeColorIcon]: showPopup,
                           [stylesModule.defaultColorIcon]: !showPopup,
                        })
                     }
                  />
               )
            }
         </div>
      );
   }
}
