import React, {Fragment} from "react";
import styles from "./Steps.module.scss";
import classNames from "classnames";
import { ReactComponent as IconCheckmark } from "../../assets/checkmark-bold-white.svg";

interface Props {
    steps: Array<any>;
    current: number;
}

export function Steps({ steps, current }: Props) {

   const dots = <div className={styles.dotsContainer}>
                     <div className={styles.dot}/>
                     <div className={styles.dot}/>
                     <div className={styles.dot}/>
                     <div className={styles.dot}/>
               </div>;

    return (
        <div className={styles.steps}>
            {steps.map(({number, text}, index) => {
                const isCurrent = current == number;
                const isCompleted = current > number;
                return (
                   <Fragment>
                       <div className={classNames(styles.step, { [styles.current]: isCurrent, [styles.completed]: isCompleted })}>
                           <div className={styles.stepIcon}>{ isCompleted ? <IconCheckmark /> : number}</div>
                           <div className={styles.stepText}>{text}</div>
                       </div>
                      {(index != (steps.length - 1)) && dots}
                   </Fragment>
                )
            })}
        </div>
    )
}
