import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { Button, ButtonTypes } from "../Button";
import { Tooltip } from "../Tooltip";
import { ValidationMessageConfig } from "../ValidationMessage";
import styles from "./MutationExpandableRow.module.scss";
import { ReactComponent as IconArrowDown } from "../../assets/arrow-down.svg";
import classNames from "classnames";

interface MutationExpandableRowProps {
   className?: string;
   content?: ReactNode;
   children(
      toggleExpand: () => void,
      expanded: boolean,
      renderMore: () => void
   ): ReactElement;
   more?: ReactNode;
   popUpMenu?: ReactNode;
   validationMessageConfig?: ValidationMessageConfig;
   messageTooltip?: string;
   tooltip?: string;
   deleteAction?: () => void;
   overViewAction?: () => void;
   handleClickRow?: () => void;
}
export function MutationExpandableRow(props: MutationExpandableRowProps) {
   const [msg, setMsg] = useState<ValidationMessageConfig | undefined>(
      undefined
   );
   const [expanded, setExpanded] = useState(false);
   const [openPopupMenu, setOpenPopupMenu] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setMsg(props.validationMessageConfig);
      }, 500);
   }, [props.validationMessageConfig]);
   return (
      <div
         className={classNames(styles.mutationExpandableRow, props.className, {
            [styles.contentWithMessage]: msg,
         })}
      >
         <div className={styles.mutationExpandableRowContent}>
            <div
               onClick={() =>
                  props.handleClickRow ? props.handleClickRow() : 1
               }
            >
               {props.children(
                  () => setExpanded(!expanded),
                  expanded,
                  () => (
                     <div
                        className={classNames(styles.more, {
                           [styles.moreVisible]: expanded,
                        })}
                     >
                        {props.more}
                     </div>
                  )
               )}
               {props.content}
            </div>
            {/* <div className={styles.moreButtonWrapper}>
               <Button
                  onClick={() => setExpanded(!expanded)}
                  type={ButtonTypes.Text}
               >
                  {expanded ? "Weniger" : "Mehr"}
               </Button>
            </div> */}
            {(props.deleteAction ||
               props.popUpMenu ||
               props.overViewAction) && (
               <div className={styles.actions}>
                  <div className={styles.leftActions}>
                     {props.deleteAction && (
                        <Button
                           onClick={() => props.deleteAction()}
                           type={ButtonTypes.Text}
                           className={styles.deleteButton}
                        >
                           Löschen
                        </Button>
                     )}
                     {props.popUpMenu && (
                        <div
                           className={classNames(styles.popupWrapper, {
                              [styles.popupWrapperActive]: openPopupMenu,
                           })}
                        >
                           <Button
                              onClick={() => setOpenPopupMenu(!openPopupMenu)}
                              type={ButtonTypes.Text}
                              className={classNames(styles.popupButton, {
                                 [styles.popupButtonActive]: openPopupMenu,
                              })}
                           >
                              ABKLÄREN
                              <IconArrowDown
                                 className={classNames(styles.arrowIcon, {
                                    [styles.arrowIconActive]: openPopupMenu,
                                 })}
                              />
                           </Button>
                           <div
                              className={classNames(styles.popupMenu, {
                                 [styles.popupMenuActive]: openPopupMenu,
                              })}
                           >
                              {props.popUpMenu}
                           </div>
                        </div>
                     )}
                     <div className={styles.popupContentWrapperMobile}>
                        <div
                           className={classNames(styles.popupMenuMobile, {
                              [styles.popupMenuActiveMobile]: openPopupMenu,
                           })}
                        >
                           {props.popUpMenu}
                        </div>
                     </div>
                  </div>
                  {props.overViewAction && (
                     <Button
                        onClick={() => props.overViewAction()}
                        type={ButtonTypes.Text}
                        className={styles.overViewButton}
                     >
                        üBERNEHMEN
                     </Button>
                  )}
               </div>
            )}
            <div
               className={classNames(
                  styles.message,
                  {
                     [styles.activeMessage]: msg,
                  },
                  styles[`${props.validationMessageConfig?.type}`]
               )}
            >
               {props?.validationMessageConfig?.message}
            </div>
         </div>
      </div>
   );
}
