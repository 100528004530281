/**
 * Renders the appropriate content depending if the predicate is true or false
 */
import {EValidationTestType} from "./validationConfigs";

// import { IDictionary } from '../models';

declare interface IDictionary<T> {
  [key: string]: T;
}

export function renderIf(
    condition: boolean,
    content: {
        ifTrue: () => JSX.Element | JSX.Element[];
        ifFalse: () => JSX.Element | JSX.Element[];
    }
): JSX.Element | JSX.Element[] {
    if (condition) {
        return content.ifTrue();
    } else {
        return content.ifFalse();
    }
}

/**
 * Renders the given content only if the condition is true
 */
export function renderIfTrue(
    condition: boolean,
    content: () => JSX.Element
): JSX.Element | JSX.Element[] | null {
    if (condition) {
        return content();
    }
    return null;
}

/**
 * Renders the given content only if the condition is false
 */
export function renderIfFalse(
    condition: boolean,
    content: () => JSX.Element | JSX.Element[]
): JSX.Element | JSX.Element[] | null {
    if (!condition) {
        return content();
    }
    return null;
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefinedTrue<T>(
    data: T,
    content: (data: T) => JSX.Element
): JSX.Element | null {
    if (typeof data !== 'undefined' && data !== null) {
        return content(data);
    } else {
        return null;
    }
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefined<T>(
    data: T,
    content: {
        isDefined: (data: T) => JSX.Element | JSX.Element[];
        isNotDefined: () => JSX.Element | JSX.Element[];
    }
): JSX.Element | JSX.Element[] {
    if (typeof data !== 'undefined' && data !== null) {
        return content.isDefined && content.isDefined(data);
    } else {
        return content.isNotDefined && content.isNotDefined();
    }
}

/**
 * Renders a callback from the callbacks dictionary based on a key (similar to the switch statement)
 * @param key               Key of the callback to call
 * @param callbacks         All the available callbacks
 * @param defaultCallback   Available if nothing else matches
 */
export function renderSwitch(
    key: string | number,
    callbacks: IDictionary<() => JSX.Element>,
    defaultCallback?: () => JSX.Element
): JSX.Element | null {
    if (callbacks[key]) {
        return callbacks[key]();
    } else {
        if (defaultCallback) {
            return defaultCallback();
        } else {
            return null;
        }
    }
}

export const parseDate = (strRaw, type?) => {
   if (strRaw) {
      let str = null;
      if (strRaw.length > 10) {
         str = strRaw;
      } else {
         str = strRaw ? strRaw.toString().replace(/\./g, "/") : null
      }
      // console.log('parseDate: ', strRaw, str, type);
      var options = {};

      if (type === 'hr-min') {
         options = {
            hour: '2-digit',
            minute: '2-digit'
         }
         return new Date(Date.parse(str)).toLocaleTimeString('de-DE', options);
      } else if (type === "dateObject") {
         return new Date(str);
      } else if (type === "secsToDDMMYYYY") {
         // console.log('secsToDDMMYYYY', strRaw, str, new Date(str), new Date(str).toLocaleDateString('de-DE', options))
         options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            // timeZone: "UTC"
         }
         return new Date(str).toLocaleDateString('de-DE', options);
      } else if (type = "toSecs") {
         return new Date(str).getTime();
      } else {
         options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
         }
         return new Date(Date.parse(str)).toLocaleDateString('de-DE', options);
      }
   } else
      return ''
};

export function formatNumber(num) {
    // console.log("formatNumber: ", num);
    if (!num) {
        return num;
    }
    if (typeof num == 'string' && /[a-zA-Z]/.test(num)) {
        return num;
    }
    if (typeof num == 'string') {
        num = num.replace(/'/g, '').replace(/,/g, '.');
    }
    return parseFloat(num).toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "\'" + c : c;
    });
}

export function formatYear(val) {
  if (val == "" || !val) return val
  val = val.replace(/[^\d]/g, '');
  if(val.length > 4) {
    val = val.slice(0, 4);
  }
  if(val.length == 4) {
    if(parseInt(val) < 1970) {
      return "1970";
    }
    if(parseInt(val) > 2100) {
      return "2100";
    }
  }

  return val;
}

export function formatMonth(val) {
  if (val == "" || !val) return val
  val = val.replace(/[^\d]/g, '');
  if(val.length > 2) {
    val = val.slice(0, 2);
  }
  if(parseInt(val) > 12) {
    val = "12";
  }
  if(val.length > 1 && parseInt(val) < 1) {
    val = "01";
  }
  return val;
}

export function formatDate(val, isFocused) {
  if (val && Array.from(val).length > 0) {
    val = val.replace(/[^\d.-]/g, '');

    if(val && val.length && val.length > 10 ) {
      val = val.substr(0, 10);
    }

    if (val == "") return val;
    var value = val;
    if (!value) return val;
    if (isFocused) {
      var parts = val.split(".");
      if (parts.length == 1) {
        if (val.length > 2) {
          value = val[0] + val[1] + "." + val[2];
        }
      }
      if (parts.length == 2) {
        if (parts[0].length == 1 && parts[1].length == 0) {
          value = "0" + parts[0] + ".";
        }
        if (parts[1].length == 3) {
          value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[1][2];
        }
      }
      if (parts.length == 3) {
        if (parts[1].length == 1 && parts[2].length == 0) {
          value = parts[0] + ".0" + parts[1] + "." + parts[2];
        }
        if (parts[1].length == 2) {
          value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[2];
        }
      }
    } else {
      var parts = val.split(".");
      if (parts.length == 1) {
        if (val.length > 2) {
          return val[0] + val[1] + "." + val[2];
        }
      }
      if (parts.length == 2) {
        if (parts[0].length == 1) {
          return "0" + parts[0] + ".";
        }
        if (parts[1].length > 2) {
          return parts[0] + "." + parts[1][0] + parts[1][1] + ".";
        }
      }
      if (parts.length == 3) {
        if (parts[2].length == 2) {
          if (parseInt(parts[2]) < 45) {
            value = parts[0] + "." + parts[1] + "." + "20" + parts[2];
          } else {
            value = parts[0] + "." + parts[1] + "." + "19" + parts[2];
          }
        } else if (parts[2].length == 4) {
          value = parts[0] + "." + parts[1] + "." + parts[2];
        }
        if (parts[0].length == 1) {
          value = "0" + parts[0] + "." + parts[1] + "." + parts[2];
        }
        if (parts[1].length == 1) {
          value = parts[0] + ".0" + parts[1] + "." + parts[2];
        }
      }
    }
    return value;
  } else {
     return val;
  }
}

export const getMonth = (date, correctingValue?) => {
   return new Date(date).getMonth() + (correctingValue || 0);
}

export const getYear = (date) => {
   return new Date(date).getFullYear();
}

export const getDate = (date) => {
   return new Date(date).getDate();
}

export const monthDiff = (d1, d2) => {
   var months;
   months = (d2.getFullYear() - d1.getFullYear()) * 12;
   months -= d1.getMonth();
   months += d2.getMonth();
   return months <= 0 ? 0 : months;
}

export const isDate = (value) => {
   let date = new Date(value);
   return date instanceof Date && !isNaN(date.valueOf());
}

export const parseDateString = (str, type?) => {
   // console.log("parseDate utils: ", str);
   if(!str) {
      return "";
   }
   if (/\d{4}\-\d{2}\-\d{2}/gm.test(str)) {
      if (str != null && str != '') {

         var d = new Date(str),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = '' + d.getHours(),
            minutes = '' + d.getMinutes();

         if (month.length < 2)
            month = '0' + month;
         if (day.length < 2)
            day = '0' + day;
         if (hours.length < 2)
            hours = '0' + hours;
         if (minutes.length < 2)
            minutes = '0' + minutes;

         if (type && type == 'hr-min') {
            return [day, month, year].join('.') + ' - ' + [hours, minutes].join(':');
         }
         // console.log("will return date matched: ", day, month, year);
         const date = [day, month, year].join('.');
         if (date.includes('NaN')) {
            return null;
         }
         return date;
      } else {
         return null;
      }
   } else {
      return null;
   }
};























