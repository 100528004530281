import React from 'react';
import gsap from 'gsap';
import {SwitchTransition, Transition} from 'react-transition-group'
import {getDate, getMonth, isDate, monthDiff, parseDate, parseDateString} from '../../utils/react';
import styles from './Calendar.module.scss';
import classNames from 'classnames';
import Day from './Day/index'
import Month from './Month/index'
import {Year} from './Year'
import {ReactComponent as IconCalendar} from "../../assets/calendar.svg";
import {ValidationMessage, ValidationMessageType} from "../ValidationMessage";

const translatableStrings = {
   chooseDay: {
      id: 'calendar.chooseDay',
      defaultMessage: 'Tag wählen'
   },
   chooseMonth: {
      id: 'calendar.chooseMonth',
      defaultMessage: 'Monat wählen'
   },
   chooseYear: {
      id: 'calendar.chooseYear',
      defaultMessage: 'Jahr wählen'
   },
   MM: {
      id: 'calendar.MM',
      defaultMessage: "MM",
   },
   DD: {
      id: 'calendar.TT',
      defaultMessage: "TT"
   },
   YYYY: {
      id: 'calendar.YYYY',
      defaultMessage: "JJJJ"
   },
   next: {
      id: 'button.next',
      defaultMessage: 'WEITER'
   },
}


interface State {
   isOpened: boolean,
   selectedDate: any,
   activeDate: string,
}

interface Props {
   name: string;
   onChange: any;
   onBlur?: any;
   readOnly?: boolean;
   isDisabled?: boolean;
   dateRange?: any;
   validationVisible?: boolean;
   validationMessageConfig?: ValidationMessage;
   value?: any;
}

export class Calendar extends React.Component<Props, State> {
   private CalendarRef: React.RefObject<HTMLInputElement>;
   private DatePickerRef: React.RefObject<HTMLInputElement>;
   private TTref: React.RefObject<HTMLInputElement>;
   private MMref: React.RefObject<HTMLInputElement>;
   private JJJJref: React.RefObject<HTMLInputElement>;
   private nextButtonRef: React.RefObject<React.Component>;
   private timeline: any = null;

   initialState = {
      isOpened: false,
      selectedDate: {
         TT: '',
         MM: '',
         JJJJ: '',
      },
      activeDate: '',
   };

   constructor(props: any) {
      super(props);
      this.state = this.initialState;
      this.CalendarRef = React.createRef();
      this.DatePickerRef = React.createRef();
      this.TTref = React.createRef();
      this.MMref = React.createRef();
      this.JJJJref = React.createRef();
      this.nextButtonRef = React.createRef();
   }

   componentDidMount() {
      document.addEventListener("click", this.handleClick);

      const date = parseDateString(this.props.value);
      if(date) {
         const parts = date.split(".");
         if(parts.length === 3) {
            this.setState({ selectedDate: { TT: parts[0], MM: parts[1], JJJJ: parts[2] } });
            this.TTref.current.value = parts[0];
            this.MMref.current.value = parts[1];
            this.JJJJref.current.value = parts[2];
         }
      }
   }

   componentWillUnmount() {
      document.removeEventListener("click", this.handleClick);
   }

   componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
      const previousDate = this.parseDate(prevState.selectedDate);
      const currentDate = this.parseDate(this.state.selectedDate);

      if(prevState.isOpened && !this.state.isOpened) {
         this.props.onBlur();
      }

      if(previousDate !== currentDate) {
         if(currentDate == "0.0.0") {
            this.props.onChange(this.props.name, "");
         } else {
            this.props.onChange(this.props.name, currentDate);
         }
      }

      const date = parseDateString(this.props.value);
      if((prevProps.value !== this.props.value) && date) {
         const parts = date.split(".");
         if(parts.length === 3) {
            this.setState({ selectedDate: { TT: parts[0], MM: parts[1], JJJJ: parts[2] } });
            this.TTref.current.value = parts[0];
            this.MMref.current.value = parts[1];
            this.JJJJref.current.value = parts[2];
         }
      }
   }

   handleClick = (e) => {
      const element = e.target;
      if(!this.CalendarRef.current.contains(element)) {
         this.closeDatePicker();
      }
   }

   openDatePicker = () => {
      if(!this.state.isOpened && !this.props.isDisabled && !this.props.readOnly) {
         this.setState({isOpened: true});
      }
   }

   closeDatePicker = () => {
      if(this.state.isOpened) {
         this.setState({isOpened: false, activeDate: ""});
         this.props.onBlur();
      }
   }

   parseDate = (dateObject) => {
      return `${dateObject.TT}.${dateObject.MM}.${dateObject.JJJJ}`;
   }

   // blurDatePicker = () => {
   //    const activeElement = document.activeElement;
   //    console.log(activeElement);
   //    console.log(this.DatePickerRef.current);
   //       if(!this.DatePickerRef.current.contains(activeElement)) {
   //          console.log("sasda");
   //          this.closeDatePicker()
   //       }
   // }

   onChangeDateParameter = (e) => {
      let value = e.currentTarget.value.replace(/\D/g, '');
      const dayInitialValue = 'TT';

      if (!(Number.isInteger(e.currentTarget.value) || Number(e.currentTarget.value) >= 0)) {
         this.setState({selectedDate: {...this.state.selectedDate, 'TT': dayInitialValue}});

         this.TTref.current.value = '';
         return;
      }

      if (e.currentTarget.value.indexOf('.') === 1) {
         value = '0' + e.currentTarget.value.replace(/\D/g, '');
         this.TTref.current.value = (parseInt(value) > 31) ? "31" : value;
      }

      if (e.currentTarget.value.length > 2) {
         value = value.slice(0, 2);
         this.TTref.current.value = (parseInt(value) > 31) ? "31" : value;
      }

      this.setState({ selectedDate: { ...this.state.selectedDate, 'TT': (parseInt(value) > 31) ? 31 : ('0' + value).slice(-2) }});

      if (value.length === 2 || e.currentTarget.value.length === 2 || e.currentTarget.value.length > 2) {
         this.TTref.current.value = (parseInt(value) > 31) ? "31" : value;
         this.setState({activeDate: 'MM'});
         this.MMref.current.value = '';
         this.MMref.current.focus();
      }
   };

   onChangeMonthParameter = async(e) => {
      let value = e.currentTarget.value.replace(/\D/g, '');
      const {selectedDate} = this.state;
      const monthInitialValue = 'MM';

      if (!(Number.isInteger(e.currentTarget.value) || Number(e.currentTarget.value) >= 0)) {
         this.setState({selectedDate: {...this.state.selectedDate, 'MM': monthInitialValue}});

         this.MMref.current.value = '';
         return;
      }

      if (e.currentTarget.value.indexOf('.') === 1) {
         value = '0' + e.currentTarget.value.replace(/\D/g, '');
         this.MMref.current.value = value;
      }

      if (e.currentTarget.value.length > 2) {
         value = value.slice(0, 2);
         this.MMref.current.value = (parseInt(value) > 12) ? "12" : value;
      }


      if (this.props.dateRange && this.props.dateRange[0] && this.props.dateRange[1] && value > 0) {
         const differenceInMonths = monthDiff(new Date(this.props.dateRange[0]), new Date(this.props.dateRange[1]));
         if (differenceInMonths < 12) {
            if (Number(value) < (getMonth(this.props.dateRange[0]) + 1)) {
               value = ('0' + Number(getMonth(this.props.dateRange[0]) + 1)).slice(-2);
            } else if (selectedDate && selectedDate.TT && (Number(value) >= (getMonth(this.props.dateRange[1]) + 1)) && (Number(selectedDate.TT) > getDate(this.props.dateRange[1]))) {
               value = ('0' + Number(getMonth(this.props.dateRange[1]))).slice(-2);
            } else if (Number(value) > (getMonth(this.props.dateRange[1]) + 1)) {
               value = ('0' + Number(getMonth(this.props.dateRange[1]) + 1)).slice(-2);
            }
         }
      }

      this.setState({ selectedDate: { ...this.state.selectedDate, 'MM': (parseInt(value) > 12) ? "12" : ('0' + value).slice(-2)} });

      if (value.length === 2) {
         this.MMref.current.value = (parseInt(value) > 12) ? "12" : value;
         this.setState({activeDate: 'JJJJ'});
         this.JJJJref.current.value = '';
         this.JJJJref.current.focus();
      }
   };

   onChangeYearParameter = async(e) => {
      console.log("ASDASD");
      let value = e.target.value.replace(/\D/g, '');
      const yearInitialValue = '0';

      if (!(Number.isInteger(e.target.value) || Number(e.target.value) >= 1)) {
         this.setState({selectedDate: {...this.state.selectedDate, 'JJJJ': yearInitialValue}});

         this.JJJJref.current.value = '';
         return;
      }

      console.log(e.target.value.length);
      if (e.target.value.length > 4) {
         value = value.slice(0, 4);
         this.JJJJref.current.value = value;
      }

      this.setState({selectedDate: {...this.state.selectedDate, 'JJJJ': value}});
      if (value.length === 4) {
         if (this.state.selectedDate.MM === "") {
            this.setState({activeDate: 'MM'});
            this.MMref.current.focus();
         } else if (this.state.selectedDate.TT === "") {
            this.setState({activeDate: 'TT'});
            this.TTref.current.focus();
         } else {
            if (this.props.dateRange) {
               if (parseDate(this.props.dateRange[0], "toSecs") <= parseDate(`${value}-${this.state.selectedDate.MM}-${this.state.selectedDate.TT}`, "toSecs")) {
                  if (parseDate(this.props.dateRange[1], "toSecs") >= parseDate(`${value}-${this.state.selectedDate.MM}-${this.state.selectedDate.TT}`, "toSecs")) {
                     this.setState({isOpened: false, activeDate: ''});
                     this.JJJJref.current.blur();
                  }
               }
            } else {
               this.setState({isOpened: false, activeDate: ''});
               this.JJJJref.current.blur();
            }

         }
      }
   };

   onClickDateParameter = async(e) => {
      // console.log("ASDASD");
      // e.stopPropagation();
      // let month = {};
      // if (e.target.id === "JJJJ") {
      //    month = { "MM": this.MMref.current.value }
      // }
      // this.setState({ activeDate: e.target.id, selectedDate: {...this.state.selectedDate, [e.target.id]: '', ...month}, isOpened: true });
   }

   onClickChangeDate = async(e) => {
      if (this.state.selectedDate.MM === "") {
         this.setState({activeDate: 'MM', selectedDate: {...this.state.selectedDate, 'TT': e.target.id}});
      } else if (this.state.selectedDate.JJJJ === "") {
         this.setState({activeDate: 'JJJJ', selectedDate: {...this.state.selectedDate, 'TT': e.target.id}});
      } else {
         this.setState({ activeDate: '', selectedDate: {...this.state.selectedDate, 'TT': e.target.id}, isOpened: false });
      }

      this.TTref.current.value = e.target.id;
   }

   onClickChangeMonth = async(e) => {
      if (this.state.selectedDate.TT === "") {
         this.setState({activeDate: 'TT', selectedDate: {...this.state.selectedDate, 'MM': e.target.id}});
      } else if (this.state.selectedDate.JJJJ === "") {
         this.setState({activeDate: 'JJJJ', selectedDate: {...this.state.selectedDate, 'MM': e.target.id}});
      } else {
         this.setState({ activeDate: '', selectedDate: {...this.state.selectedDate, 'MM': e.target.id}, isOpened: false});
      }

      this.MMref.current.value = e.target.id;
   }

   onClickChangeYear = async(e) => {
      if (this.state.selectedDate.MM === "") {
         this.setState({activeDate: 'MM', selectedDate: {...this.state.selectedDate, 'JJJJ': e.target.id}});
      } else if (this.state.selectedDate.TT === "") {
         this.setState({activeDate: 'TT', selectedDate: {...this.state.selectedDate, 'JJJJ': e.target.id}});
      } else {
         this.setState({ activeDate: '', selectedDate: {...this.state.selectedDate, 'JJJJ': e.target.id}, isOpened: false });
      }
      this.JJJJref.current.value = e.target.id;
   }

   onFocusDateParameter = (e) => {
      this.openDatePicker();
      this.setState({ activeDate: "TT" });
   }

   onFocusMonthParameter = (e) => {
      this.openDatePicker();
      this.setState({ activeDate: "MM" });
   }

   onFocusYearParameter = (e) => {
      console.log("focused year");
      this.openDatePicker();
      this.setState({ activeDate: "JJJJ" });
   }

   onBlurDateParameter = (e) => {
      // this.blurDatePicker();
   }

   onBlurMonthParameter = (e) => {
      // this.blurDatePicker();
   }

   onBlurYearParameter = (e) => {
      // this.blurDatePicker();
   }

   onCalendarIconClick = () => {
      if(!this.state.isOpened) {
         this.openDatePicker();
         this.TTref.current.focus();
      } else {
         this.closeDatePicker();
      }
   }

   playIn = (node, appears?) => {
      console.log('playIn', node, appears, this.state.activeDate, appears);
      if (!appears) {
         if (this.state.activeDate === 'MM') {
            this.timeline.to(document.querySelector('.datePicker'), {maxHeight: 468, duration: 0.2});
            this.timeline.fromTo(node.querySelector('.months'), {x: 500, autoAlpha: 0}, {
               x: 0,
               duration: 0.3,
               autoAlpha: 1
            });
            this.timeline.fromTo(node.querySelector('.clue'), {autoAlpha: 0}, {duration: 0.3, autoAlpha: 1, delay: 0});
         } else if (this.state.activeDate === 'DD') {
            this.timeline.to(document.querySelector('.datePicker'), {maxHeight: 468, duration: 0.3});
            // this.timeline.fromTo(node.querySelector('.days'), { x: 500, autoAlpha: 0 }, { x: 0, duration: 0.3, autoAlpha: 1 });
         } else if (this.state.activeDate === 'JJJJ') {
            this.timeline.to(document.querySelector('.datePicker'), {maxHeight: 468, duration: 0.3});
            this.timeline.fromTo(node.querySelector('.years'), {x: 500, autoAlpha: 0}, {
               x: 0,
               duration: 0.3,
               autoAlpha: 1
            });
            this.timeline.fromTo(node.querySelector('.clue'), {autoAlpha: 0}, {duration: 0.3, autoAlpha: 1, delay: 0});
         } else {
            console.log("FOCUS BUTTONm");
            this.timeline.fromTo(document.querySelector('.confirmationDialog'), {autoAlpha: 0}, {
               duration: 0.3,
               autoAlpha: 1,
               delay: 0.15
            });
            setTimeout(() => {
               document.querySelector('.focusableButton') && (document.querySelector('.focusableButton') as any).focus();
            }, 200);
         }
         this.timeline.play();
      }
   }

   playOut = (node) => {
      console.log('playOut', node, this.state.activeDate);
      this.timeline = gsap.timeline({paused: true});
      this.timeline && this.timeline.pause();

      if (this.state.activeDate === 'MM') {
         this.timeline.fromTo(node.querySelector('.days'), {x: 0, autoAlpha: 1}, {
            x: -500,
            duration: 0.3,
            autoAlpha: 0
         }, 0);
         this.timeline.fromTo(node.querySelector('.clue'), {autoAlpha: 1}, {duration: 0.3, autoAlpha: 0, delay: 0}, 0);
      } else if (this.state.activeDate === 'JJJJ') {
         this.timeline.fromTo(node.querySelector('.clue'), {autoAlpha: 1}, {duration: 0.3, autoAlpha: 0, delay: 0}, 0);
         this.timeline.fromTo(node.querySelector('.months'), {x: 0, autoAlpha: 1}, {
            x: -500,
            duration: 0.3,
            autoAlpha: 0
         }, 0);
      } else if (this.state.activeDate === "") {
         console.log('datepicier height animn out');
         this.timeline.to(document.querySelector('.datePicker'), {maxHeight: 232, duration: 0.3}, 0);
         this.timeline.fromTo(node.querySelector('.years'), {autoAlpha: 1}, {duration: 0.3, autoAlpha: 0}, 0);
         this.timeline.fromTo(node.querySelector('.clue'), {autoAlpha: 1}, {duration: 0.3, autoAlpha: 0, delay: 0}, 0);
      } else {
         console.log('datepicier alpha animn out');
         this.timeline.fromTo(document.querySelector('.confirmationDialog'), {autoAlpha: 1}, {
            duration: 0.3,
            autoAlpha: 0,
            delay: 0
         });
      }

      this.timeline.play();
   }

   render() {
      return (
         <div className={styles.calendar} ref={this.CalendarRef}>
            <div className={styles.datePicker} ref={this.DatePickerRef}>
               <div className={classNames(styles.date,
                  {[styles.disabled]: this.props.isDisabled || this.props.readOnly},
                  {[styles.error]: this.props.validationMessageConfig && (this.props.validationMessageConfig.config.type == ValidationMessageType.Error) && this.props.validationVisible})}>

                  <input
                     disabled={this.props.isDisabled}
                     readOnly={this.props.readOnly}
                     className={classNames(styles.dateParameter, styles.dateParameterDate, styles.test, {[styles.activeDateParameter]: this.state.activeDate === 'TT'})}
                     id='TT'
                     placeholder='TT'
                     ref={this.TTref}
                     spellCheck={false}
                     autoComplete="off"
                     onChange={this.onChangeDateParameter}
                     onClick={this.onClickDateParameter}
                     onFocus={this.onFocusDateParameter}
                     onBlur={this.onBlurDateParameter}>
                  </input>

                  <div className={classNames(styles.dateParameter, styles.point)}>.</div>

                  <input
                     disabled={this.props.isDisabled}
                     readOnly={this.props.readOnly}
                     className={classNames(styles.dateParameter, styles.dateParameterMonth, styles.test, {[styles.activeDateParameter]: this.state.activeDate === 'MM'})}
                     id='MM'
                     placeholder='MM'
                     ref={this.MMref}
                     spellCheck={false}
                     autoComplete="off"
                     onChange={this.onChangeMonthParameter}
                     onClick={this.onClickDateParameter}
                     onFocus={this.onFocusMonthParameter}
                     onBlur={this.onBlurMonthParameter}>
                  </input>

                  <div className={classNames(styles.dateParameter, styles.point)}>.</div>

                  <input
                     disabled={this.props.isDisabled}
                     readOnly={this.props.readOnly}
                     className={classNames(styles.dateParameter, styles.dateParameterYear, styles.test, {[styles.activeDateParameter]: this.state.activeDate === 'JJJJ'})}
                     id='JJJJ'
                     placeholder='JJJJ'
                     ref={this.JJJJref}
                     spellCheck={false}
                     autoComplete="off"
                     onChange={this.onChangeYearParameter}
                     onClick={this.onClickDateParameter}
                     onFocus={this.onFocusYearParameter}
                     onBlur={this.onBlurYearParameter}
                     onKeyDown={(e) => {
                        console.log(e.key);
                        if (e.key === "Tab") {
                           this.closeDatePicker();
                        }
                     }}>
                  </input>

                  <span onClick={this.onCalendarIconClick}>
                     <IconCalendar className={styles.calendarIcon}/>
                  </span>

               </div>

               <SwitchTransition mode="out-in">
                  <Transition
                     key={`child${this.state.activeDate}`}
                     timeout={{enter: 0, exit: 0}}
                     // onEnter={(node?, appears?) => this.playIn(node, appears)}
                     // onExit={(node?) => this.playOut(node)}
                  >

                     <div
                        className={classNames(styles.choseDate, {[styles.isOpened]: this.state.isOpened})}>
                        <SwitchTransition mode="out-in">
                           <Transition
                              key={`child${this.state.activeDate}`}
                              timeout={{enter: 0, exit: 0}}
                              // onEnter={(node, appears?) => this.playIn(node, appears)}
                              // onExit={(node?) => this.playOut(node)}
                           >
                                <span>
                                    {this.state.activeDate === 'TT' ?
                                       <React.Fragment>
                                          <div className={styles.clue}>Tag wählen</div>
                                          <Day onClick={(e) => this.onClickChangeDate(e)}/>
                                       </React.Fragment>
                                       : null}

                                   {this.state.activeDate === 'MM' ?
                                      <React.Fragment>
                                         <div className={styles.clue}>Monat wählen</div>
                                         <Month
                                            selectedDate={this.state.selectedDate}
                                            onClick={(e) => this.onClickChangeMonth(e)}
                                            dateRange={this.props.dateRange}
                                            lang={"en"}
                                         />
                                      </React.Fragment>
                                      : null}

                                   {this.state.activeDate === 'JJJJ' ?
                                      <React.Fragment>
                                         <div className={styles.clue}>Jahr wählen</div>
                                         <Year
                                            selectedDate={this.state.selectedDate}
                                            dateRange={this.props.dateRange}
                                            // second={this.props.second ? 2090 : null}
                                            // reversed={this.props.yearReversed}
                                            onClick={(e) => this.onClickChangeYear(e)}
                                         />
                                      </React.Fragment>
                                      : null}
                                </span>
                           </Transition>
                        </SwitchTransition>
                     </div>

                  </Transition>
               </SwitchTransition>
            </div>
         </div>
      );
   }
}
