import { ReactComponent as HelpIcon } from "../../assets/help-icon.svg";
import style from "./Tooltip.module.scss";
import React, { useEffect, useState } from "react";

interface HintProps {
   text: string;
   whiteIcon?: boolean;
}

export function Tooltip({ text, whiteIcon }: HintProps) {
   const [hover, setHover] = useState(false);
   const [showTooltip, setShowTooltip] = useState(false);

   useEffect(() => {
      if (hover) {
         setShowTooltip(hover);
      }
   }, [hover]);

   useEffect(() => {
      if (!showTooltip) {
         setTimeout(() => {
            setHover(showTooltip);
         }, 500);
      }
   }, [showTooltip]);

   return (
      <span
         className="help-icon"
         id="tooltip-icon"
         style={{ display: "contents", cursor: "pointer" }}
         onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setShowTooltip(false)}
      >
         {hover && (
            <div
               className={style.sharedTooltip}
               style={{
                  opacity: showTooltip ? 1 : 0,
                  left:
                     175 -
                     (document?.getElementById("tooltip-content")
                        ?.offsetWidth || 0) /
                        2,
               }}
               id="tooltip-content"
            >
               {text}
               <br />
               <span
                  style={{
                     fontSize: 40,
                     color: "rgb(36, 47, 56)",
                     position: "absolute",
                     textAlign: "center",
                     bottom: -18,
                     left: "calc(50% - 14px)",
                  }}
               >
                  &#9670;
               </span>
            </div>
         )}
         <HelpIcon
            className={`${style.helpIcon} ${
               showTooltip
                  ? style.activeHelpIcon
                  : whiteIcon
                  ? style.deActiveHelpIcon
                  : ""
            }`}
         />
      </span>
   );
}
