import React from 'react';
import {ReactComponent as PlusIcon} from "../../../assets/plus.svg";
import {StyledButton, StyledPlusButtonCircle} from './styles'
import { PlusButtonProps } from './types'


export const PlusButton: React.FC<PlusButtonProps> = ({text, onClick, isDisabled}:PlusButtonProps) => {
   return (
      <StyledButton onClick={onClick} disabled={isDisabled}>
         <StyledPlusButtonCircle>
             <PlusIcon/>
          </StyledPlusButtonCircle>
         {text}
      </StyledButton>
   )
}
