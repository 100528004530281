import React from 'react';

import styles from "./Card.module.scss";
import {Button, ButtonColors, ButtonTypes} from "../Button";
import {ReactComponent as EditIcon} from "../../assets/edit-icon.svg";
import {ReactComponent as DownloadIcon} from "../../assets/download-icon.svg";

interface Props {
   title: string;
   status?: any;
   text1: string;
   text2: string;
   onEditClick?: any;
   onPdfClick?: any;
}

export const Card: React.FC<Props> = ({title, status, text1, text2, onEditClick, onPdfClick}: Props) => {
   return (
      <div tabIndex={0} className={styles.card}>
         <div className={styles.cardTop}>
            <div>
               <b>{title}</b>
               {status}
            </div>

            <div className={styles.cardIcons}>
               {onEditClick && <Button
                  className={styles.editIcon}
                  type={ButtonTypes.Text}
                  color={ButtonColors.Action}
                  onClick={onEditClick}>
                  <EditIcon/>
               </Button>}

               {onPdfClick && <Button
                  className={styles.pdfIcon}
                  type={ButtonTypes.Text}
                  color={ButtonColors.Action}
                  onClick={onPdfClick}>
                  <DownloadIcon/>
               </Button>}
            </div>
         </div>

         <div className={styles.cardBottom}>
            <span>{text1}</span>
            <span>{text2}</span>
         </div>
      </div>
   )
}
