import React from "react";
import classNames from 'classnames';
import * as S from "./styles";

interface AdditionalSavingsProps {
    id?: string;
    title: string;
    status?: string;
    subtitle: string;
    value: string;
    large?: boolean;
    disabled?: boolean;
    selected?: boolean;
    onClick: () => void;
}

export const AdditionalSavingsCard:React.FC<AdditionalSavingsProps> = (props:AdditionalSavingsProps) => {
    
    return(
        <S.AdditionalCard {...props} 
        large={props.large} 
        id={props.id} 
        className={classNames({
            selected: props.selected,
            disabled: props.disabled
        })} 
        onClick={()=>{
           !props.disabled && props.onClick()}
        }>
            <div>
                <S.AdditionalCardTitle className={classNames({
                    disabled: props.disabled
                })}>
                    {props.title}
                    {!!props.status && <S.AdditionalCardTag className={classNames({
                    disabled: props.disabled
                })}>{props.status}</S.AdditionalCardTag>}
                </S.AdditionalCardTitle>
                <S.AdditionalCardSubtitle>{props.subtitle}</S.AdditionalCardSubtitle>
            </div>
            <S.AdditionalCardDescription large={props.large}>
                <S.AdditionalCardSalary className={classNames({
                    disabled: props.disabled
                })}>CHF {props.value}</S.AdditionalCardSalary>
            </S.AdditionalCardDescription>
        </S.AdditionalCard>
    )
}