import React from 'react';
import styles from "./Blank.module.scss";
import classNames from "classnames";
import {Button, ButtonColors, ButtonTypes} from "../Button";

interface Props {
   text: string;
   buttonText?: string;
   className?: any;
   showButton?: boolean;
   onButtonClick?: () => void;
}

export const Blank: React.FC<Props> = ({text, buttonText, showButton, onButtonClick, className}: Props) => {
   return (
      <div className={classNames(styles.blank, className, {[styles.noButton]: !showButton})}>
         <span className={styles.blankText}>
            {text}
         </span>
         {showButton &&
         <Button
            className={styles.blankButton}
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            onClick={() => onButtonClick()}>
            {buttonText}
         </Button>}
      </div>
   )
}
