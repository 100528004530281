import styled from "@emotion/styled";

export const AdditionalCard = styled.div<{large?:boolean}>`
    padding: 32px;
    display: flex;
    flex-direction: ${({large }) => large ? "row" : "column" };
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 18px 60px -30px rgba(38,50,56,0.2);
    cursor: pointer;
    border: 2px solid #fff;
    margin-right: 24px;
    flex:1;
    transition: all 0.2s linear;
    width: ${({large }) => large ? "100%" : "auto" };
    justify-content: ${({large }) => large ? "space-between" : "initial" };
    
    &:last-child {
        margin-right: 0;
    }

    &.selected {
        border-color: #1B8DB3;
    }

    &.disabled {
        color: #969899;
        cursor: not-allowed;
    }


    &:hover {
        box-shadow: 0 27px 100px -20px rgba(38, 50, 56, 0.2),
          0 4px 15px -10px rgba(38, 50, 56, 0.2),
          0 9px 30px -15px rgba(38, 50, 56, 0.2),
          0 18px 60px -30px rgba(38, 50, 56, 0.2);  
      }

    
    @media (max-width: 768px) {
        margin-bottom: 36px;
        width: auto;
    }
    
`

export const AdditionalCardTitle = styled.div`
    display: flex;
    color: #242F38;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 24px;

    &.disabled {
        color: #969899;
    }

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        margin-bottom: 16px;

        & > .additionalCardTag {
            margin-left: 0;
            margin-bottom: 12px;
            width: 65px;
        }
    }
`

export const AdditionalCardTag = styled.div`
    padding: 3px 12px;
    text-transform: uppercase;
    color: #fff;
    background: #1B8DB3;
    border-radius: 12px;
    font-size: 12px;
    height: 20px;
    font-weight: bold;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-left: 16px;

    &.disabled {
        color: #fff;
        background: #969899;
    }

    @media (max-width: 768px) {
        margin-left: 0;
        margin-bottom: 12px;
        width: max-content;
    }
`

export const AdditionalCardDescription = styled.div<{large:boolean}>`
    display:flex;
    flex-direction: ${({ large })=> large ? "column-reverse" : "initial"};
    color: #242F38;
    font-family: Inter;
`

export const AdditionalCardSubtitle = styled.div`
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 4px;
`

export const AdditionalCardSalary = styled.div`
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;

    &.disabled {
        color: #969899;
    }
`