import React from 'react';
import classNames from 'classnames'

import styles from "./Sticky.module.scss";
import {Button, ButtonColors, ButtonStyles, ButtonTypes} from "../Button";
import {ReactComponent as Arrow} from "../../../assets/arrow-right.svg";
import * as S from './styles'
import { StickyProps } from './types'


export const Sticky: React.FC<StickyProps> = ({content, buttonText, className, onClick}: StickyProps) => {
   const statusClasses = classNames(styles.sticky, className);
   
   return (
      <S.StyledSticky className={statusClasses}>
         <S.StyledContent>
            {content}
         </S.StyledContent>

         <Button
            icon={<Arrow/>}
            type={ButtonTypes.Standard}
            style={ButtonStyles.Primary}
            color={ButtonColors.WhiteInvert}
            isDisabled={false}
            onClick={onClick}
         >
            {buttonText}
         </Button>
      </S.StyledSticky>
   )
}
