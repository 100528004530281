import * as React from 'react'
import {FC} from 'react'
import classNames from 'classnames'

import styles from './CircularRadio.module.scss'

interface IProps {
   isFocused: boolean;
   isLoading: boolean;
   name?: string;
   value?: string;
   fullWidth?: boolean;
   checked?: boolean;
   defaultValue?: boolean;
   readOnly?: boolean;
   label: string;
   type: string;
   onChange: (name: string, value: boolean) => void;
   additionalClass?: string;
   isDisabled?: boolean;
   onBlur: any;
}

const IconRadio: FC<{ isChecked: boolean; isLabel: boolean }> = ({isChecked, isLabel}) => (
   <div className={classNames(styles.circle, {[styles.hasLabel]: isLabel})}>
         {isChecked && (
            <div className={styles.innerCircle}/>
         )}
   </div>
);

export const InputCircularRadio: FC<IProps> = (props) => (
   <label
      htmlFor={props.name}
      tabIndex={0}
      onBlur={props.onBlur}
      className={classNames(styles.label, {[styles.full]: props.fullWidth}, {[styles.checked]: props.checked})}
      onKeyPress={(event) => {
         var code = event.keyCode || event.which
         if (code === 13) {
            props.onChange(props.name, !props.checked)
         }
      }}
   >
      <IconRadio isChecked={props.checked} isLabel={!!props.label}/>

      {props.label}

      <input
         tabIndex={-1}
         id={props.name}
         className={styles.radio}
         type="radio"
         readOnly={props.readOnly}
         name={props.name}
         onBlur={props.onBlur}
         checked={props.checked}
         defaultChecked={props.defaultValue}
         value={props.value}
         disabled={props.isDisabled}
         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.currentTarget.name, e.currentTarget.checked)
         }}
      />
   </label>
);
