import * as React from 'react';
import classNames from 'classnames';
import { defined } from '../../utils/variableEvaluation';

import styles from './ValidationMessage.module.scss';

export const EMPTY_VALIDATION_MESSAGE_CONFIG = { message: '' };

export enum ValidationMessageType {
  Standard = 'standard',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export interface State {
  //  Currently empty
}

export interface ValidationMessage {
   config: ValidationMessageConfig;
   visible?: boolean;
}

export interface ValidationMessageConfig {
  message: string;
  type?: ValidationMessageType;
}

export function ValidationMessage(props: ValidationMessage) {
  const isStandard =
    !defined(props.config?.type) || props.config?.type === ValidationMessageType.Standard;
  const isSuccess = props.config?.type === ValidationMessageType.Success;
  const isWarning = props.config?.type === ValidationMessageType.Warning;
  const isError = props.config?.type === ValidationMessageType.Error;

  const validationClass = classNames(
    [styles.validationMessage],
            "validation-message",
    {
      [styles.standard]: isStandard,
      [styles.success]: isSuccess,
      [styles.warning]: isWarning,
      [styles.error]: isError,
      [styles.empty]: !(props.config?.message && props.config?.message.length > 0)
    }
  );
  return <div className={validationClass}>{props.config?.message || ''}</div>;
}
