import * as React from 'react';
import classNames from "classnames";

import {InputWrapper} from './InputWrapper';
import {InputLabel} from './Label';
import {EInputType, InputContent} from './Content';
import {renderDefinedTrue} from '../../utils/react';
import {ValidationMessage, ValidationMessageConfig} from '../ValidationMessage';
import {EValidationTestType} from '../../utils/validationConfigs';
import {defined} from "../../utils/variableEvaluation";
import styles from './InputWrapper.module.scss';

export enum EInputTextType {
   Standard = 'Standard',
   Material = 'Material'
}

export interface IInputConfig {
   // @TODO fix it for radio buttons!
   checkboxLabel?: any;
   radioLabel?: any;
   checked?: boolean;
   clearable?: boolean;
   customComponent?: JSX.Element;
   defaultChecked?: boolean;
   defaultValidationMessageConfig?: ValidationMessageConfig;
   defaultValue?: any;
   disabled?: boolean;
   dropdownLabel?: string;
   forcedValue?: any;
   id?: string;
   inputTextType?: EInputTextType;
   inputType?: EInputType;
   inputWrapperClass?: string;
   inputWrapperStyle?: React.CSSProperties;
   isFocused?: boolean;
   isLoading?: boolean;
   label?: any;
   max?: number;
   maxLength?: number;
   min?: number;
   name?: string;
   onBlur?: any;
   onChange?: (name: any, value?: any, file?: any) => void;
   onDropdownOpen?: (isOpen: boolean) => void;
   onSelected?: any;
   placeholder?: string;
   radioOptions?: IInputConfig[];
   readOnly?: boolean;
   selectOptions?: IInputConfig[];
   step?: string;
   style?: any;
   type?: string;
   validationMessageConfig?: ValidationMessage;
   validationTests?: EValidationTestType[];
   value?: any;
   filtering?: boolean;
   cols?: number;
   rows?: number;
   isOpen?: boolean;
   hidden?: boolean;
   onFocus?: any;
   sort?: boolean;
   fileInputLabel?: string
   numeric?: boolean;
   isCountriesDropdown?: boolean;
   hideClearDropdownIcon?: boolean;
   hideArrowDropdownIcon?: boolean;
   isDatepicker?: boolean;
   validationVisible?: boolean;
}

export class Input extends React.Component<IInputConfig, {}> {
   state = {
      validationVisible: false
   }

   onBlur = (e) => {
      this.setState({validationVisible: true});
      this.props.onBlur && this.props.onBlur(e);
   }

   render(): JSX.Element {
      const {
         validationMessageConfig,
         inputWrapperStyle,
         children,
         style,
         inputWrapperClass,
         sort,
         type
      } = this.props;
      const validationConfigVisible = validationMessageConfig?.visible;
      const validationVisible = defined(validationConfigVisible) ? (validationConfigVisible || this.state.validationVisible) : this.state.validationVisible;

      return (
         <InputWrapper
            validationMessage={validationMessageConfig}
            hidden={this.props.hidden}
            validationVisible={validationVisible}
            inputWrapperStyle={inputWrapperStyle}
            inputWrapperClass={classNames(inputWrapperClass || "", {
               [styles.checkboxWrapper]: type === 'checkbox',
               [styles.radioWrapper]: type === 'radio'
            })}
         >
            {renderDefinedTrue(children, () => (
               <InputLabel style={style || {}}>{children}</InputLabel>
            ))}

            {renderDefinedTrue(validationMessageConfig, () => (
               validationVisible && <ValidationMessage {...validationMessageConfig} />
            ))}

            <InputContent inputConfig={{...this.props, validationMessageConfig, validationVisible, onBlur: this.onBlur}}/>
         </InputWrapper>
      );
   }
}
